import React from 'react'
import { graphql } from 'gatsby'
import { truncate } from 'lodash';
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import remark from 'remark'
import remarkHtml from 'remark-html'
import recommended from 'remark-preset-lint-recommended'

import Content, { HTMLContent } from '../components/Content'
import { FluxibleIconBlock } from '../components/FluxibleIcon'
import Layout from '../components/layout'
import SpeakerBio from '../components/SpeakerBio'
import moment from 'moment-timezone'

import { formatDate, formatDuration, TZ } from '../util/events'

const EventTemplate = ({
  content,
  contentComponent,
  name,
  affiliatedPresenter,
  startDate,
  endDate,
  location,
  eventType,
  speakers,
  registrationLink,
}) => {
  const EventContent = contentComponent || Content

  return (
    <section className="section grid-container">
      <div className="event-container">
        {eventType && eventType.displayName && (
          <div className="event_event_type">
            {eventType.displayName}
            {speakers.length > 0 && (<>
              {' with '}
              {speakers.map(s => s.name).join(', ')}
            </>)}
          </div>
        )}
        <h1 className="event_title_header">
          {name}
        </h1>
        <div className="fluxible_date_header event_date_header">
          <span className="event_time">
            {formatDuration(startDate, endDate)} {" "} {moment.tz(TZ).zoneAbbr()}
          </span>{' '}
          {formatDate(startDate)}

          {location &&
            location.name && location.fields && (
              <a className="event_location" href={location.fields.slug}>
                <FluxibleIconBlock
                  icon="building"
                  text={location.name}
                  size="sm"
                />
              </a>
            )}

        </div>


        {affiliatedPresenter &&
          affiliatedPresenter !== '' && (
            <p className="is-italic">Presented by: {affiliatedPresenter}</p>
          )}

        <EventContent content={content} className="event_content" />

        {registrationLink && eventType && eventType.name.includes('Meetup') &&
          (
            <a className="button action-button" href={registrationLink}>Register for this Meetup</a>
          )}

        {/* (eventType && eventType.name === 'Fluxible Workshop' && registrationLink) ?
          <a className="button action-button" href={registrationLink}>Register for this workshop</a> : '' */}

        {speakers && (
          <>
            {Object.keys(speakers).map(key => {
              const s = speakers[key]
              if (!s || s.bio === "" || (s.photo.name === "/media/defaultAvatar.png" && moment(startDate).isBefore(new Date("2019-01-01T00:00:00")))) return null
              return <SpeakerBio speaker={s} key={s.name + `speaker`} />
            })}
          </>
        )}
      </div>
    </section>
  )
}

EventTemplate.propTypes = {
  name: PropTypes.string,
  description: PropTypes.string,
  affiliatedPresenter: PropTypes.string,
  startDate: PropTypes.string,
  endDate: PropTypes.string,
  location: PropTypes.object,
  eventType: PropTypes.object,
  speakers: PropTypes.array,
  registrationLink: PropTypes.string,
}

class Event extends React.Component {
  componentDidMount() {
    window.sessionStorage.setItem('eventLoaded', 'true')
  }

  render() {
    const { event } = this.props.data

    const description = remark()
      .use(recommended)
      .use(remarkHtml)
      .processSync(event.description)
      .toString()

    const meta = [
      { property: 'og:title', content: truncate(event.name, { 'length': 70, 'omission': '\u2026' }) },
      { property: 'og:description', content: truncate(event.summary, { 'length': 200, 'omission': '\u2026' }) },
      { name: 'twitter:card', content: event.featuredImage !== null ? 'summary_large_image' : 'summary' },
    ]

    if (event.speakers && event.speakers.length && event.speakers[0].photo) {
      const imageName = event.featuredImage !== null ?
        process.env.GATSBY_WEBSITE_URL + event.featuredImage.name :
        process.env.GATSBY_WEBSITE_URL + event.speakers[0].photo.name
      meta.push({ property: 'og:image', content: imageName })
      meta.push({ name: 'twitter:image:alt', content: event.name + ' with ' + event.speakers[0].name })
    }
    return (
      <Layout>
        <Helmet
          title={`${event.name} ${event.speakers.length > 0 ? `with ${event.speakers[0].name}` : ''} | ${event.eventType.name.replace(/\s\d*-.*/g, '')} ${event.startDate.slice(0, 4)}`}
          meta={meta}
        />
        <EventTemplate
          content={description}
          contentComponent={HTMLContent}
          name={event.name}
          affiliatedPresenter={event.affiliatedPresenter}
          startDate={event.startDate}
          endDate={event.endDate}
          location={event.location}
          eventType={event.eventType}
          speakers={event.speakers}
          registrationLink={event.registrationLink}
        />
      </Layout>
    )
  }
}

Event.propTypes = {
  data: PropTypes.shape({
    fluxibleCmsEvents: PropTypes.object,
  }),
}

export default Event

export const pageQuery = graphql`
  query CmsEventByID($id: String!) {
    event: fluxibleEvents(id: { eq: $id }) {
      id
      name
      description
      summary
      affiliatedPresenter
      startDate
      endDate
      registrationLink
      eventType {
        name
        displayName
      }
      featuredImage {
        name
        altText
        contentType
      }
      speakers {
        name
        jobPosition
        bio
        socialMedia {
          type
          value
        }
        photo {
          name
          altText
          contentType
        }
      }
      location {
        name
        fields {
          slug
        }
      }
    }
  }
`
