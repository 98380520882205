import React from 'react'
import PropTypes from 'prop-types'
import remark from 'remark'
import remarkHtml from 'remark-html'
import recommended from 'remark-preset-lint-recommended'

import Content, { HTMLContent } from '../components/Content'
import { getImage, getSocialMedia } from '../util'

const SpeakerBio = ({ speaker }) => {
  const bio = remark()
    .use(recommended)
    .use(remarkHtml)
    .processSync(speaker.bio)
    .toString()

  const SpeakerContent = HTMLContent || Content

  return (
    <div className="event_speaker_content">
      <h5 className="event_speaker_title">About {speaker.name}</h5>
      <h6 className="subtitle h6 is-italic">{speaker.jobPosition}</h6>
      {getImage(speaker.photo, speaker.name, "event_speaker_photo")}

      <SpeakerContent content={bio} />
      {getSocialMedia(speaker.socialMedia, true, speaker.name)}
    </div>
  )
}

SpeakerBio.propTypes = {
  speaker: PropTypes.object,
}

export default SpeakerBio
